.App {
  text-align: center;
  font-family: "Inria Sans", sans-serif;
  background-color: whitesmoke;
  height: 100dvh;
  min-height: fit-content;
  display: flex;
  flex-direction: column;
  font-size: calc(10px + 2vmin);
  align-items: center;
  overflow: hidden;
  position: relative;
}

.container {
  max-width: 1300px;
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 16px;
}

.card {
  width: 350px;
  height: 350px;
  background-color: #F5F5F5;
  border-radius: 4px;
  box-shadow: 0 15px 10px rgb(0 0 0 / 0.2);
}

/* ======================================================================== */

.navbar {
  background-color: #F5F5F5;
  height: 80px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.navbar-items {
  font-weight: normal;
  font-size: x-large;
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.navbar-contact {
  color: #F03333;
  cursor: pointer;
}

.navbar-contact:hover {
  color: #da2d2d;
}

/* ======================================================================== */

.hero {
  width: 100%;
  max-height: fit-content;
  min-height: fit-content;
  display: flex;
  justify-content: center;
  padding-top: 32px;
  padding-bottom: 32px;
  background-color: whitesmoke;
}

.hero-items {
  width: 100%;
  display: flex;
}

.hero-img-container {
  width: 80%;
  min-width: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.hero-img {
  width: 100%;
  max-width: 534px;
  max-height: 693px;
}

.hero-text-container {
  width: 100%;
  margin-top: -140px;
  display: flex;
  flex-direction: column;
  text-align: left;
  justify-content: center;
}

.hero-text {
  font-weight: 600;
  font-size: 70px;
  transition: ease-in 200ms;
}

.hero-contact-button {
  margin-top: 40px;
  font-size: large;
  color: white;
  width: 120px;
  height: 48px;
  border: 0px;
  background-color: #F03333;
  transition: ease-in-out 100ms;
  cursor: pointer;
  border-radius: 4px;
}

.hero-contact-button:hover {
  background-color: #da2d2d;
}

a {
  text-decoration: none;
}

.aws {
  padding: 8px 0px 8px 0px;
  color: black;
  font-size: x-large;
  display: flex;
  bottom: 0;
  align-items: center;
  cursor: pointer;
}

.aws-img {
  width: 30px;
  margin-right: 8px;
}

/* ======================================================================== */
.quick-links-top {
  position: absolute;
  z-index: 0;
  top: 0;
  clip-path: polygon(100% 100%, 0% 100% , 0.00% 3.07%, 2.00% 2.57%, 4.00% 2.14%, 6.00% 1.79%, 8.00% 1.50%, 10.00% 1.30%, 12.00% 1.18%, 14.00% 1.14%, 16.00% 1.19%, 18.00% 1.32%, 20.00% 1.53%, 22.00% 1.82%, 24.00% 2.19%, 26.00% 2.62%, 28.00% 3.13%, 30.00% 3.69%, 32.00% 4.30%, 34.00% 4.95%, 36.00% 5.64%, 38.00% 6.36%, 40.00% 7.10%, 42.00% 7.84%, 44.00% 8.58%, 46.00% 9.31%, 48.00% 10.02%, 50.00% 10.70%, 52.00% 11.34%, 54.00% 11.93%, 56.00% 12.48%, 58.00% 12.96%, 60.00% 13.37%, 62.00% 13.71%, 64.00% 13.98%, 66.00% 14.16%, 68.00% 14.26%, 70.00% 14.28%, 72.00% 14.22%, 74.00% 14.07%, 76.00% 13.84%, 78.00% 13.53%, 80.00% 13.15%, 82.00% 12.70%, 84.00% 12.18%, 86.00% 11.61%, 88.00% 10.99%, 90.00% 10.32%, 92.00% 9.63%, 94.00% 8.91%, 96.00% 8.17%, 98.00% 7.43%, 100.00% 6.69%);
  background-color: #F03333;
  width: 100%;
  height: 300px;
}

.quick-links {
  position: relative;
  background-color: #F03333;
  z-index: 10;
  width: 100%;
  padding: 50px 0px 25px 0px;
  min-height: fit-content;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

.quick-links-bottom {
  position: absolute;
  z-index: 0;
  bottom: 0;
  clip-path: polygon(100% 0%, 0% 0% , 0.00% 88.26%, 2.00% 87.76%, 4.00% 87.32%, 6.00% 86.96%, 8.00% 86.67%, 10.00% 86.47%, 12.00% 86.34%, 14.00% 86.31%, 16.00% 86.35%, 18.00% 86.49%, 20.00% 86.70%, 22.00% 87.00%, 24.00% 87.37%, 26.00% 87.81%, 28.00% 88.32%, 30.00% 88.89%, 32.00% 89.52%, 34.00% 90.18%, 36.00% 90.89%, 38.00% 91.62%, 40.00% 92.36%, 42.00% 93.12%, 44.00% 93.87%, 46.00% 94.62%, 48.00% 95.34%, 50.00% 96.03%, 52.00% 96.68%, 54.00% 97.29%, 56.00% 97.84%, 58.00% 98.33%, 60.00% 98.75%, 62.00% 99.10%, 64.00% 99.37%, 66.00% 99.56%, 68.00% 99.66%, 70.00% 99.68%, 72.00% 99.61%, 74.00% 99.46%, 76.00% 99.23%, 78.00% 98.91%, 80.00% 98.53%, 82.00% 98.07%, 84.00% 97.54%, 86.00% 96.96%, 88.00% 96.33%, 90.00% 95.65%, 92.00% 94.94%, 94.00% 94.21%, 96.00% 93.46%, 98.00% 92.70%, 100.00% 91.95%);
  background-color: #F03333;
  width: 100%;
  height: 300px;
}

.quick-links-items {
  width: 100%;
  display: flex;
  justify-content: space-around;
}

.card-title {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 25%;
}

.card-img {
  border-radius: 4px;
  width: 100%;
  height: 75%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

#github-link .card-img {
  background-image: url('./coding.jpg');
}

#github-link {
  transition: ease-in-out 200ms;
}

#github-link:hover {
  translate: 0px -10px;
  box-shadow: 0 25px 10px rgb(0 0 0 / 0.2);
}

#linkedin-link .card-img {
  background-image: url('./linkedin.png');
}

#linkedin-link  {
  transition: ease-in-out 200ms;
}

#linkedin-link:hover {
  translate: 0px -10px;
  box-shadow: 0 25px 10px rgb(0 0 0 / 0.2);
}

#resume-link .card-img {
  background-size: cover;
  background-image: url('./OIP\ \(2\).jpg');
}

#resume-link  {
  transition: ease-in-out 200ms;
}

#resume-link:hover {
  translate: 0px -10px;
  box-shadow: 0 25px 10px rgb(0 0 0 / 0.2);

}

/* ======================================================================== */

.contact-form {
  overflow: hidden;
  width: 100%;
  min-height: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: whitesmoke;
}

.contact-form-items {
  margin-top: 30px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  overflow: hidden;
}

.contact-form-items .card {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-width: 300px;
  min-height: fit-content;
  box-shadow: 0 15px 10px rgba(0, 0, 0, 0);
}

.contact-form-button {
  margin-top: 16px;
  font-size: large;
  color: white;
  width: 100%;
  height: 48px;
  border: 0px;
  background-color: #F03333;
  border-radius: 4px;
  transition: ease-in-out 100ms;
  cursor: pointer;
}

.contact-form-button:hover {
  background-color: #da2d2d;
}

.contact-img {
  width: 680px;
  min-width: 680px;
  height: 500px;
  min-height: 500px;
  background-image: url('./plane2.png');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

label {
  font-size: x-large;
  text-align: left;
  display: block;
  margin-bottom: 8px;
  margin-top: 8px;
}

input, textarea {
  border: 1px solid black;
  border-radius: 4px;
  max-width: 100%;
  width: 100%;
  padding: 8px;
  box-sizing: border-box;
}

textarea {
  height: 110px;
  max-height: 110px;
  min-height: 110px;
  min-width: 100%;
}

.error {
  margin: 0px;
  text-align: left;
  color: red;
  font-size: small;
}

.to-top-btn {
  position: absolute;
  z-index: 10;
  bottom: 0;
  font-size: large;
  color: black;
  width: 150px;
  height: 56px;
  border: 0px;
  background-color: transparent;
  transition: ease-in-out 100ms;
  cursor: pointer;
  border-radius: 4px;
}

@media (max-width:1150px) {
  /* big landscape tablets, laptops, and desktops */ 
  .hero-text {
    font-size: 60px;
  }
  .contact-img {
    margin-top: 50px;
    width: 480px;
    min-width: 480px;
    height: 400px;
    min-height: 400px;
  }
}


@media (max-width:1080px) {
  /* big landscape tablets, laptops, and desktops */ 
  .card {
    width: 300px;
    height: 300px;
  }


}

@media (max-width:990px) {
  /* big landscape tablets, laptops, and desktops */ 
  .hero-img {
    order: 1;
    width: 60%;
    min-width: 369px;
    min-height: 479px;
  }

  .hero-items {
    flex-direction: column;
    align-items: center;
  }
  
  .hero-text-container {
    order: 2;
    margin-top: 0px;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin-bottom: 50px;
  }
}


@media (max-width:960px) {
  .quick-links-items {
    flex-direction: column;
    align-items: center;
  }

  .contact-img {
    display: none;
  }

  .contact-form-items {
    margin-top: 15px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    overflow: hidden;
    padding: 0px 40px 48px 40px;
  }

  #contact.card {
    margin-top: 0px;
  }
}


@media (max-width: 760px) {
  /* big landscape tablets, laptops, and desktops */ 
  .hero-text {
    font-size: 50px;
  }

  .aws {
    font-size: 22px;
  }

  .contact-img {
    display: none;
  }
}

@media (max-width: 500px) {
  /* big landscape tablets, laptops, and desktops */ 
  .hero-text {
    font-size: 40px;
  }

}
